import { BuyerSelfRegisterModalComponent } from '@cosBuyer/partials/buyer-self-register-modal/buyer-self-register-modal.component';
import { Observable, of, map } from 'rxjs';
import { BuyerActivationTask, IBuyerActivationTasks, ICompanyContactData } from '@caronsale/cos-models';
import { BuyerVehiclePreferencesComponent } from '@cosCoreFeatures/buyer/components/buyer-vehicle-preferences/buyer-vehicle-preferences.component';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { ComponentType } from '@angular/cdk/portal';
import { CompanyContactModalComponent } from '@cosCoreComponentsGeneral/company-contact-modal/company-contact-modal.component';
import { COS_CONSTANTS } from '@caronsale/frontend-services';
import { IBuyerCockpitActivityCard } from './cockpit-activity-cards.component';
import { Icon } from '@caronsale/enzo';

export const SORTED_COCKPIT_CARD_TYPES = [
  'blocked',
  'buyerLimitPurchaseBlocked',
  'buyerLimitPurchaseWarning',
  'openPickups',
  'openPayments',
  'openCheckouts',
  'outbid',
  'openRenegotiations',
  'setAuctionPreferences',
  'uploadDocuments',
  'checkAuctions',
] as const;

export type TBuyerCockpitCardType = (typeof SORTED_COCKPIT_CARD_TYPES)[number];

export type BuyerCockpitActivityCardButtonConfig =
  | {
      type: 'route';
      translationKey: string;
      targetRoute: string;
    }
  | {
      type: 'dialog';
      translationKey: string;
      targetDialog: ComponentType<any>; // shall make sure that it is a Component class
      targetDialogConfigFactory: () => Observable<any>;
    };

export interface IBuyerCockpitActivityCardConfig {
  kind: 'grey' | 'black' | 'yellow' | 'orange' | 'red' | 'green';
  header: {
    icon: Icon;
    translationKey: string;
  };
  content: {
    headlineTranslationKey: string;
    translationKey?: string;
  };
  tag?: {
    translationKey: string;
  };
  button: BuyerCockpitActivityCardButtonConfig;
}

interface IBuyerCockpitCardTemplate {
  type: TBuyerCockpitCardType;
  paramsFactory?: (param: BuyerActivationTask['value']) => { [key: string]: string };
}

// used in a unit test
export const CHECK_AUCTIONS_CARD_ICON = 'car-category';

export function getActivityCardsConfig(
  cosBuyerClientService: CosBuyerClientService,
  constants: COS_CONSTANTS,
): { [key in TBuyerCockpitCardType]: IBuyerCockpitActivityCardConfig } {
  const contactDialogFactory = () =>
    cosBuyerClientService.getBuyerUserCompanyContact().pipe(
      map((companyContact: ICompanyContactData) => ({
        data: {
          mailAddress: constants.CONTACT_PHONE,
          phoneNumber: constants.SUPPORT_EMAIL,
          businessHours: constants.BUSINESS_HOURS,
          ...companyContact,
        },
      })),
    );

  return {
    uploadDocuments: {
      kind: 'black',
      header: {
        icon: 'upload',
        translationKey: 'buyer-cockpit.upload-documents.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.upload-documents.content-headline',
        translationKey: 'buyer-cockpit.upload-documents.content',
      },
      button: {
        type: 'dialog',
        translationKey: 'buyer-cockpit.upload-documents.button',
        targetDialog: BuyerSelfRegisterModalComponent,
        targetDialogConfigFactory: () => of({ width: '450px' }),
      },
    },

    setAuctionPreferences: {
      kind: 'black',
      header: {
        icon: 'search',
        translationKey: 'buyer-cockpit.set-auction-preferences.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.set-auction-preferences.content-headline',
        translationKey: 'buyer-cockpit.set-auction-preferences.content',
      },
      button: {
        type: 'dialog',
        translationKey: 'buyer-cockpit.set-auction-preferences.button',
        targetDialog: BuyerVehiclePreferencesComponent,
        targetDialogConfigFactory: () =>
          cosBuyerClientService.getBuyerUserProfile().pipe(
            map(userProfile => ({
              width: '1000px',
              hasBackdrop: true,
              disableClose: false,
              data: {
                isPreregisteredAccount: userProfile.isPreregisteredAccount,
              },
            })),
          ),
      },
    },

    checkAuctions: {
      kind: 'black',
      header: {
        icon: CHECK_AUCTIONS_CARD_ICON,
        translationKey: 'buyer-cockpit.check-auctions.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.check-auctions.content-headline',
        translationKey: 'buyer-cockpit.check-auctions.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.check-auctions.button',
        targetRoute: '/salesman/auctions',
      },
    },

    openRenegotiations: {
      kind: 'yellow',
      header: {
        icon: 'renegotiation',
        translationKey: 'buyer-cockpit.open-renegotiations.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.open-renegotiations.content-headline',
        translationKey: 'buyer-cockpit.open-renegotiations.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.open-renegotiations.button',
        targetRoute: '/salesman/purchases/below-min-ask',
      },
    },

    outbid: {
      kind: 'yellow',
      header: {
        icon: 'outbid',
        translationKey: 'buyer-cockpit.outbid.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.outbid.content-headline',
        translationKey: 'buyer-cockpit.outbid.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.outbid.button',
        targetRoute: '/salesman/auctions?tabId=2&limit=20&offset=0',
      },
    },

    openCheckouts: {
      kind: 'grey',
      header: {
        icon: 'check-outline',
        translationKey: 'buyer-cockpit.open-checkouts.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.open-checkouts.content-headline',
        translationKey: 'buyer-cockpit.open-checkouts.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.open-checkouts.button',
        targetRoute: '/salesman/purchases',
      },
    },

    openPayments: {
      kind: 'orange',
      header: {
        icon: 'price',
        translationKey: 'buyer-cockpit.open-payments.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.open-payments.content-headline',
        translationKey: 'buyer-cockpit.open-payments.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.open-payments.button',
        targetRoute: '/salesman/purchases',
      },
    },

    openPickups: {
      kind: 'orange',
      header: {
        icon: 'ready-to-drive',
        translationKey: 'buyer-cockpit.open-pickups.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.open-pickups.content-headline',
        translationKey: 'buyer-cockpit.open-pickups.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.open-pickups.button',
        targetRoute: '/salesman/purchases/pickup',
      },
      tag: {
        translationKey: 'buyer-cockpit.open-pickups.badge',
      },
    },

    blocked: {
      kind: 'red',
      header: {
        icon: 'remove-outline',
        translationKey: 'buyer-cockpit.blocked.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.blocked.content-headline',
        translationKey: 'buyer-cockpit.blocked.content',
      },
      button: {
        type: 'dialog',
        translationKey: 'buyer-cockpit.blocked.button',
        targetDialog: CompanyContactModalComponent,
        targetDialogConfigFactory: contactDialogFactory,
      },
    },
    buyerLimitPurchaseWarning: {
      kind: 'orange',
      header: {
        icon: 'price',
        translationKey: 'buyer-cockpit.buyer-limit-purchase-warning.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.buyer-limit-purchase-warning.content-headline',
        translationKey: 'buyer-cockpit.buyer-limit-purchase-warning.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.buyer-limit-purchase-warning.button',
        targetRoute: '/salesman/purchases',
      },
    },

    buyerLimitPurchaseBlocked: {
      kind: 'red',
      header: {
        icon: 'remove-outline',
        translationKey: 'buyer-cockpit.buyer-limit-purchase-blocked.header',
      },
      content: {
        headlineTranslationKey: 'buyer-cockpit.buyer-limit-purchase-blocked.content-headline',
        translationKey: 'buyer-cockpit.buyer-limit-purchase-blocked.content',
      },
      button: {
        type: 'route',
        translationKey: 'buyer-cockpit.buyer-limit-purchase-blocked.button',
        targetRoute: '/salesman/purchases',
      },
    },
  };
}

// sorted by priority
const MAP_ACTIVITY_TO_CARD: { [key in keyof IBuyerActivationTasks]: IBuyerCockpitCardTemplate } = {
  showBiddingIsProhibited: { type: 'blocked' },
  showBuyerLimitPurchaseBlocked: { type: 'buyerLimitPurchaseBlocked' },
  showBuyerLimitPurchaseWarning: {
    type: 'buyerLimitPurchaseWarning',
    paramsFactory: numOfOpenPayments => ({ numPayments: String(numOfOpenPayments) }),
  },
  showNumOfVehiclesToPickup: {
    type: 'openPickups',
    paramsFactory: numOfVehiclesToPickup => ({ numPickups: String(numOfVehiclesToPickup) }),
  },
  showNumOfOpenPayments: {
    type: 'openPayments',
    paramsFactory: numOfOpenPayments => ({ numPayments: String(numOfOpenPayments) }),
  },
  showNumOfOpenCheckouts: {
    type: 'openCheckouts',
    paramsFactory: numOfOpenCheckout => ({ numCheckouts: String(numOfOpenCheckout) }),
  },
  showNumOfOutbidAuctions: {
    type: 'outbid',
    paramsFactory: numOfOutbidAuctions => ({ numOutbids: String(numOfOutbidAuctions) }),
  },
  showNumOfOngoingRenegotiations: {
    type: 'openRenegotiations',
    paramsFactory: numOfOngoingRenegotiations => ({ numRenegotiations: String(numOfOngoingRenegotiations) }),
  },
  showNeedsToSetAuctionPreference: { type: 'setAuctionPreferences' },
  showNeedsToUploadDocuments: { type: 'uploadDocuments' },
  showCanCheckAuctions: null, // this is the default card that the activity-cards component shows if the array is empty, so we just exclude it here
};

export function mapBuyerActivationTasksToActivityCards(buyerActivationTasks: IBuyerActivationTasks): IBuyerCockpitActivityCard[] {
  return (Object.entries(buyerActivationTasks) as [keyof IBuyerActivationTasks, BuyerActivationTask][])
    .filter(([key, { active }]) => Boolean(MAP_ACTIVITY_TO_CARD[key]) && active)
    .map(([type, { value }]) => ({
      type: MAP_ACTIVITY_TO_CARD[type].type,
      params: MAP_ACTIVITY_TO_CARD[type].paramsFactory?.(value),
    }))
    .sort((a, b) => SORTED_COCKPIT_CARD_TYPES.indexOf(a.type) - SORTED_COCKPIT_CARD_TYPES.indexOf(b.type));
}
