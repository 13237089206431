// Angular
import { Component, HostBinding, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivationEnd, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { filter, map, Observable, take } from 'rxjs';

// Vendor
import LogRocket from 'logrocket';

// CoS
import { COS_CONSTANTS, CosCoreClient, CosI18nService } from '@caronsale/frontend-services';
import { environment } from '@cosCoreEnvironments/environment';
import { IAuthenticationResult, IServiceMetaData } from '@caronsale/cos-models';
import { GeneralIncompatibleBrowserModalComponent } from '@cosCoreComponents/incompatible-browser-modal/general-incompatible-browser-modal.component';
import { BrowserDetectionService } from '@cosCoreServices/browser-detection/browser-detection.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { I18nErrorDialogComponent } from '@cosCoreComponentsGeneral/i18n/error-dialog/i18n-error-dialog.component';
import { I18nInfoDialogComponent } from '@cosCoreComponentsGeneral/i18n/info-dialog/i18n-info-dialog.component';

import { defineCustomElements, EnzoLanguageChangePayload } from '@caronsale/enzo/loader';
import { TranslateService } from '@ngx-translate/core';
import { LogRocketInitConfig } from '@cosCoreConfig/LogRocketInitConfig';
import { GrowthbookService } from '@cos/services/growthbook/growthbook.service';

defineCustomElements();

export const HIDE_MOBILE_FULLSCREEN_OVERLAY = 'hideMobileFullscreenOverlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // Force refresh after 6 hours
  private readonly FORCE_REFRESH_THRESHOLD_IN_MS: number = 6 * 3600 * 1000;

  public uiEnvironmentLabel: string;
  public serviceEnvironmentLabel: string;
  public bannerIsHidden: boolean;
  public showErrorDialog: boolean;
  public hideMobileFullscreenOverlay: boolean;

  @HostBinding('@.disabled')
  public animationsDisabled = Boolean(window['Cypress']);

  public constructor(
    public dialog: MatDialog,
    public translateService: TranslateService,
    private router: Router,
    private cosCoreClient: CosCoreClient,
    private browserDetection: BrowserDetectionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private i18nService: CosI18nService,
    private constants: COS_CONSTANTS,
    private growthbookService: GrowthbookService,
  ) {
    this.uiEnvironmentLabel = environment.label;
    this.serviceEnvironmentLabel = '?';
    this.bannerIsHidden = false;
    this.i18nService.init();
  }

  public ngOnInit(): void {
    // redirect to /pickup for pin[-staging|-dev].caronsale.de domains
    if (window.location.host.startsWith('pin')) {
      // @see https://github.com/angular/angular/issues/12157#issuecomment-799331302
      this.router.events
        .pipe(
          filter((event): event is RoutesRecognized => !!event && event instanceof RoutesRecognized),
          take(1),
          map(event => event.state.root.queryParamMap.get('id')),
        )
        .subscribe(id => this.router.navigate(['/pickup'], { queryParams: { id } }));
    }

    // Initiate LogRocket, if desired:
    if (environment.logRocketIdentifier && !environment.amplitudeSessionReplayEnabled) {
      LogRocket.init(environment.logRocketIdentifier, LogRocketInitConfig);
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.pageEmitter('section', event.url);
      }
      if (event instanceof NavigationStart) {
        this.hideMobileFullscreenOverlay = false;
      }
      if (event instanceof ActivationEnd && event.snapshot.data?.[HIDE_MOBILE_FULLSCREEN_OVERLAY]) {
        this.hideMobileFullscreenOverlay = true;
      }
    });

    if (this.browserDetection.isInternetExplorer()) {
      this.dialog.open(GeneralIncompatibleBrowserModalComponent, {
        disableClose: true,
      });
      return;
    }

    this.showErrorDialog = false;

    this.cosCoreClient.setUnauthorizedHandler(() => {
      // TODO: What to do if that happens?
    });

    this.cosCoreClient.setSessionExpiredHandler(() => {
      // Go back to login page
      this.router.navigate(['/login']).then(() => {
        if (!this.showErrorDialog) {
          this.showErrorDialog = true;

          I18nErrorDialogComponent.show(this.dialog, 'error.session-expired', '', '450px').subscribe(() => this.cosCoreClient.signOff());
        }
      });
    });

    this.cosCoreClient.setNotFoundHandler(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;

        I18nErrorDialogComponent.show(this.dialog, 'error.not-available-try-again', '', '450px').subscribe(() => (this.showErrorDialog = false));
      }
    });

    this.cosCoreClient.setServerErrorHandler(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;

        I18nErrorDialogComponent.show(
          this.dialog,
          'error.server-problems-try-again-or-contact',
          '',
          { infoEmail: this.constants.INFO_EMAIL },
          '450px',
        ).subscribe(() => (this.showErrorDialog = false));
      }
    });

    const serviceNotAvailable$ = new Observable<number>(observer => {
      let serviceNotAvailableCount = 0;
      this.cosCoreClient.setServiceNotAvailableHandler(() => {
        serviceNotAvailableCount++;
        setTimeout(() => {
          observer.next(serviceNotAvailableCount);
          serviceNotAvailableCount = 0;
        }, 10000);
      });
    });

    serviceNotAvailable$.subscribe(serviceNotAvailableCount => {
      if (!this.showErrorDialog && serviceNotAvailableCount > 5) {
        this.showErrorDialog = true;
        I18nErrorDialogComponent.show(this.dialog, 'dialog.general.connection-issue', '', '450px').subscribe(() => (this.showErrorDialog = false));
      }
    });

    // TODO: Do we need this?
    this.cosCoreClient.setNoConnectionHandler(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;

        I18nErrorDialogComponent.show(this.dialog, 'error.server-not-reachable', '', '450px').subscribe(() => (this.showErrorDialog = false));
      }
    });

    // TODO: Do we really need this here?
    this.cosCoreClient.setBidProhibitedError(() => {
      if (!this.showErrorDialog) {
        this.showErrorDialog = true;

        I18nInfoDialogComponent.show(this.dialog, 'dialog.general.bidding-not-allowed').subscribe(_ => {
          this.showErrorDialog = false;
        });
      }
    });

    const checkIfRefreshIsNecessary = (now: number) => {
      const lastUpdate: number = Number(localStorage.getItem('lastPageRefresh'));

      if (lastUpdate) {
        // If last update has happened
        if (lastUpdate < now - this.FORCE_REFRESH_THRESHOLD_IN_MS) {
          console.log(`Last refresh has happened ${(now - lastUpdate) / 1000} seconds ago. Refreshing....`);
          localStorage.setItem('lastPageRefresh', now.toString());
          location.reload();
        } else {
          console.log(`Last refresh has happened ${(now - lastUpdate) / 1000} seconds ago. Therefore not refreshing.`);
        }
      } else {
        // Initial refresh
        localStorage.setItem('lastPageRefresh', now.toString());
      }
    };

    this.cosCoreClient.getServiceMetaInfo().subscribe((metaInfo: IServiceMetaData) => {
      this.serviceEnvironmentLabel = metaInfo.environment;
      checkIfRefreshIsNecessary(metaInfo.currentTimeInMilliseconds);
    });

    if (localStorage.getItem('persistedAuthentication')) {
      this.cosCoreClient.validateAuthentication().subscribe((response: IAuthenticationResult) => {
        if (response?.authenticated) {
          // Persist updated authentication
          this.cosCoreClient.updateAuthenticationData(response);

          this.growthbookService.setUserIdAttribute(response.userId);
          // adding current user to logrocket to track the shit out of them
          LogRocket.identify(response.userId, {
            userType: response.type,
          });
        } else {
          this.router.navigate(['/login']);
          this.cosCoreClient.signOff();
        }
      });
    }
  }

  public isServiceProductionEnvironment(): boolean {
    return this.serviceEnvironmentLabel === 'production' || this.serviceEnvironmentLabel === '?';
  }

  public isUIProductionEnvironment(): boolean {
    return environment.label === 'production';
  }

  public languageChange(event: CustomEvent<EnzoLanguageChangePayload>) {
    const newLanguage = event.detail.newLanguage;

    this.cosCoreClient.setLanguage(newLanguage).subscribe();

    this.i18nService.selectLanguage(newLanguage);

    this.translateService.use(newLanguage);

    this.googleAnalyticsService.eventEmitter('language-choose', 'user', 'click', 'language-choose', newLanguage.toUpperCase());
  }
}
