import { DestroyRef, inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EUserLookAndFeelId, EAuctionLookAndFeelId } from '@caronsale/cos-models';
import { EAdvertisementTypes } from '@cosBuyer/auctions/room/EAdvertisementType';
import { CosGeneralUserClientService } from '@cosCoreServices/cos-general-user-client/cos-general-user-client.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { BehaviorSubject, distinctUntilChanged, filter, map, switchMap } from 'rxjs';

export enum EPartnerName {
  MERCEDES_BENZ = 'Mercedes-Benz',
}

export interface LookAndFeel {
  partnerName?: EPartnerName;
  visibleAdvertisementTypes?: EAdvertisementTypes[];
  partnerLogo?: string;
  partnerIcon?: string;
  banner?: string;
  bannerGradient?: string;
  searchFilterPredefinedMake?: string;
}

const LNF_REPO: Record<EUserLookAndFeelId, LookAndFeel> = {
  [EUserLookAndFeelId.DEFAULT]: {
    visibleAdvertisementTypes: [],
    bannerGradient: 'linear-gradient(98deg, #B3B3B3 0%, #404040 100%)',
  },
  [EUserLookAndFeelId.MERCEDES_BENZ]: {
    partnerName: EPartnerName.MERCEDES_BENZ,
    visibleAdvertisementTypes: [EAdvertisementTypes.MercedesBenz],
    bannerGradient:
      'radial-gradient(87% 109% at 50% 52%, #00FFFF00 0%, #000000FF 100%),radial-gradient(57% 87% at 85% 66%, #98F9FAFF 0%, #00000000 100%),radial-gradient(250% 133% at 93% 19%, #FFD2FBDE 0%, #00000000 100%),linear-gradient(0deg, #000000FF 0%, #000000FF 100%)',
    searchFilterPredefinedMake: 'Mercedes-Benz',
    partnerIcon: 'caronsale/look-and-feel/mercedes/partner-icon',
    partnerLogo: 'caronsale/look-and-feel/mercedes/partner-logo',
  },
};

const AUCTION_ROOM_LNF_REPO: Record<EAuctionLookAndFeelId, LookAndFeel> = {
  [EAuctionLookAndFeelId.DEFAULT]: {
    ...LNF_REPO.DEFAULT,
    banner: 'caronsale/look-and-feel/default/banner',
  },
  [EAuctionLookAndFeelId.MERCEDES_BENZ]: {
    ...LNF_REPO.MERCEDES_BENZ,
    banner: 'caronsale/look-and-feel/mercedes/banner',
  },
};

@Injectable()
export class LookAndFeelService implements OnDestroy {
  // dependencies
  private generalUserClient = inject(CosGeneralUserClientService);
  private analyticsService = inject(ProductAnalyticsService);
  private destroyRef = inject(DestroyRef);

  private selectedLNFSubject = new BehaviorSubject<LookAndFeel>(null);
  public selectedLookAndFeel$ = this.selectedLNFSubject.pipe(filter(Boolean));

  public init() {
    this.analyticsService
      .isOn('mpw-look-and-feel')
      .pipe(
        filter(Boolean),
        switchMap(() =>
          this.generalUserClient.generalUser$.pipe(
            map(u => (u?.account?.lookAndFeelIds ?? []).find(lnf => lnf !== EUserLookAndFeelId.DEFAULT && LookAndFeelService.getUserLookAndFeelById(lnf))),
            distinctUntilChanged(),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(lookAndFeel => {
        this.setLookAndFeelAsActive(lookAndFeel ?? EUserLookAndFeelId.DEFAULT);
      });
  }
  public ngOnDestroy(): void {
    this.selectedLNFSubject.complete();
  }

  public static getLookAndFeelForAuctionRoom(id: EAuctionLookAndFeelId): LookAndFeel {
    return AUCTION_ROOM_LNF_REPO[id] || AUCTION_ROOM_LNF_REPO[EAuctionLookAndFeelId.DEFAULT];
  }

  public static getUserLookAndFeelById(id: EUserLookAndFeelId): LookAndFeel | null {
    return LNF_REPO[id];
  }

  public static getAuctionRoomLookAndFeelById(id: EAuctionLookAndFeelId): LookAndFeel | null {
    return AUCTION_ROOM_LNF_REPO[id];
  }

  private setLookAndFeelAsActive(lookAndFeelId: EUserLookAndFeelId): void {
    let selectedLNF = LNF_REPO[lookAndFeelId];
    selectedLNF = selectedLNF || LNF_REPO[EUserLookAndFeelId.DEFAULT];

    this.selectedLNFSubject.next(selectedLNF);
  }

  public resetLookAndFeel() {
    this.selectedLNFSubject.next(null);
  }
}
