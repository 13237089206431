import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { NotificationCenterComponent } from '@cosCoreFeatures/common/notification-center/notification-center.component';
import { NotificationDialogComponent } from '@cosCoreFeatures/common/notification-center/components/notification-dialog/notification-dialog.component';
import { NotificationDialogItemComponent } from '@cosCoreFeatures/common/notification-center/components/notification-dialog-item/notification-dialog-item.component';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { NotificationToastComponent } from '@cosCoreFeatures/common/notification-center/components/notification-toast/notification-toast.component';
import { BuyerGuaranteeAdvertiseDialogModule } from '@cosCoreFeatures/buyer/components/buyer-guarantee-advertise-dialog/buyer-guarantee-advertise-dialog.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

@NgModule({
  imports: [
    //
    BaseModule,
    CommonModule,
    GeneralPipesModule,
    LottieModule.forRoot({ player: () => player }),
    NgxSkeletonLoaderModule,
    BuyerGuaranteeAdvertiseDialogModule,
    EnzoComponentsModule,
  ],
  declarations: [NotificationCenterComponent, NotificationDialogComponent, NotificationDialogItemComponent, NotificationToastComponent],
  exports: [NotificationCenterComponent, NotificationDialogComponent, NotificationDialogItemComponent],
})
export class NotificationCenterModule {}
