<ng-template #loaderSkeleton>
  <app-cockpit-carousel-skeleton />
</ng-template>

<div class="container" *ngIf="activityCards$ | async as activityCards">
  <div class="header" [class.has-multiple-cards]="activityCards.length > 1">
    <app-cockpit-activity-cards [activityCards]="activityCards" (refresh)="refreshActivityCards()" testid="activity-cards"></app-cockpit-activity-cards>
    @if (searchRevampFeatureFlag() === true) {
      <app-cockpit-quick-search-revamp testid="quick-search-revamp" />
    } @else if (searchRevampFeatureFlag() === false) {
      <app-cockpit-quick-search testid="quick-search" />
    }
  </div>
  <div class="sections">
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="auctionRooms$"
      [minItems]="2"
      titleIcon="car-category"
      [titleLabel]="'buyer-cockpit.auction-room' | translate"
      [showMoreVehiclesCard]="false"
      testid="auction-rooms-section">
      <ng-template let-items #content>
        <app-auction-room-card
          *ngFor="let auctionRoom of items.items; trackBy: trackByAuctionRoomUuid"
          [auctionRoom]="auctionRoom"
          testid="auction-room-card"></app-auction-room-card>
      </ng-template>
    </app-cockpit-section>
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="privateAuctions$"
      titleIcon="private-owner"
      [titleLabel]="'buyer-cockpit.private-auctions' | translate"
      [isNewFeature]="true"
      [buttonLabel]="'buyer-cockpit.go-to-private-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, PRIVATE_AUCTIONS_FILTER, COCKPIT_LIST_TYPE.PrivateVehicle)"
      testid="private-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="runningPrivateAuctionDetailsUrlTemplate"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.PrivateVehicle, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>
    <!-- Mercedes-Benz buyers only -->
    @if (selectedLookAndFeel$ | async; as selectedLookAndFeel) {
      @if (hasMBAdvertisementType(selectedLookAndFeel.visibleAdvertisementTypes) && selectedLookAndFeel.partnerName === partnerName) {
        <app-cockpit-section
          class="mb-section"
          [style.--backgroundGradient]="selectedLookAndFeel.bannerGradient"
          [partnerLogo]="selectedLookAndFeel.partnerLogo"
          [loaderTemplate]="loaderSkeleton"
          [source]="mercedesBenzAuctions$"
          [titleLabel]="'buyer-cockpit.mercedes-benz-section.title' | translate"
          titleColor="white"
          [noItemsText]="'buyer-cockpit.mercedes-benz-section.no-auctions-found' | translate"
          buttonIcon="arrow-right"
          [isNewFeature]="false"
          buttonColor="white"
          [minItems]="0"
          [buttonLabel]="'buyer-cockpit.go-to-mercedes-benz-auctions' | translate"
          (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, MERCEDES_BENZ_AUCTIONS_FILTER)"
          testid="mb-auctions-section">
          <ng-template let-items #content>
            <app-cockpit-auction-card
              *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
              [auctionUuid]="auctionUuid"
              [auctionDetailsUrlTemplate]="runningMercedesBenzAuctionDetailsUrlTemplate"></app-cockpit-auction-card>
          </ng-template>
        </app-cockpit-section>
      }
    }
    <!-- recommended vehicles -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="recommendedAuctions$"
      titleIcon="recommended"
      [titleLabel]="'buyer-cockpit.recommended-auctions' | translate"
      [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RECOMMENDED_AUCTIONS)"
      testid="recommended-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [recommendationId]="items.recommendationId"
          [auctionDetailsUrlTemplate]="recommendedAuctionDetailsUrlTemplate"
          testid="recommended-auction"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.Recommended, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>
    <!-- high value vehicles -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="highValueVehiclesAuctions$"
      titleIcon="vehicle-high-price"
      [titleLabel]="'buyer-cockpit.high-value-vehicles-auctions' | translate"
      [isNewFeature]="true"
      [minItems]="5"
      [buttonLabel]="'buyer-cockpit.go-to-high-value-vehicles-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, HIGH_VALUE_VEHICLES_AUCTIONS_FILTER, COCKPIT_LIST_TYPE.HighValueVehicle)"
      testid="private-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="runningHighValueVehiclesAuctionDetailsUrlTemplate"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.HighValueVehicle, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>
    <!-- electric vehicles -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="electricVehiclesAuctions$"
      titleIcon="vehicle-electric"
      [titleLabel]="'buyer-cockpit.electric-vehicle-auctions' | translate"
      [isNewFeature]="true"
      [minItems]="5"
      [buttonLabel]="'buyer-cockpit.go-to-electric-vehicle-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, ELECTRIC_VEHICLES_AUCTIONS_FILTER, COCKPIT_LIST_TYPE.ElectricVehicle)"
      testid="private-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="runningElectricVehiclesAuctionDetailsUrlTemplate"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.ElectricVehicle, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>
    <!-- watchlist vehicles -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="watchlistAuctions$"
      titleIcon="favorite"
      [titleLabel]="'buyer-cockpit.watchlist-auctions' | translate"
      [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.WATCHLIST)"
      testid="watchlist-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="watchlistAuctionDetailsUrlTemplate"
          testid="watchlist-auction"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.Watchlist, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>
    <!-- recent auctions -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="runningAuctions$"
      titleIcon="car-category"
      [titleLabel]="'buyer-cockpit.latest-auctions' | translate"
      [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS)"
      testid="running-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="runningAuctionDetailsUrlTemplate"
          testid="running-auction"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.Running, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>
  </div>
</div>
