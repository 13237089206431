import { CarOnSaleUtils } from '@cosCoreUtils/CarOnSaleUtils';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { CurrencyEuroPipe, MileagePipe } from '@caronsale/frontend-pipes';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { combineLatest, debounceTime, filter, map, Observable, startWith } from 'rxjs';
import {
  EEuroNorm,
  EFuelType,
  ETransmissionType,
  EVehicleCategory,
  EVehicleColor,
  EVehicleDoors,
  EVehicleReadyToDriveStatus,
} from '@caronsale/cos-vehicle-models';
import { ESellerBusinessType, I18nFormatUtils } from '@caronsale/cos-models';
import { enumToArray } from '@cosCoreUtils/type-script-utils';
import { AuctionSearchDropdownOption } from '@cosBuyer/partials/filters/Utils';
import { LookAndFeelService } from './look-and-feel/look-and-feel.service';

const decimalPipe = new DecimalPipe('de');
export const mileagePipe: MileagePipe = new MileagePipe(decimalPipe);
export const currencyPipe: CurrencyEuroPipe = new CurrencyEuroPipe(new CurrencyPipe('de'));

export const MIN_PS = 10;
export const MAX_PS = 450;
export const STEP_PS = 10;
export const PS_TO_KW = 0.7355;

@Injectable()
export class AuctionFilterRangesService {
  public constructor(
    private translateService: TranslateService,
    private cosBuyerClient: CosBuyerClientService,
    private lnfService: LookAndFeelService,
  ) {}

  private countryOptions() {
    return combineLatest([this.cosBuyerClient.getCurrentBuyerUser(), this.translateService.onLangChange.pipe(startWith(null))]).pipe(
      filter(([user, _]) => !!user),
      map(([user, _]) => {
        let availableCountries = [...CarOnSaleUtils.AVAILABLE_COUNTRIES];

        if (availableCountries.includes(user.countryCode)) {
          availableCountries = [user.countryCode, ...availableCountries.filter(countryCode => countryCode !== user.countryCode)];
        }

        return [
          ...availableCountries.map(
            countryCode =>
              ({
                value: countryCode,
                label: this.translateService.instant('country-label.' + countryCode.toLowerCase()),
              }) as AuctionSearchDropdownOption,
          ),
        ];
      }),
    );
  }

  private convertHPtoKW(hp: number) {
    return Math.floor(hp * PS_TO_KW);
  }

  public getRanges(): Observable<Record<string, AuctionSearchDropdownOption[]>> {
    return combineLatest([this.countryOptions(), this.lnfService.selectedLookAndFeel$.pipe(startWith(null))]).pipe(
      debounceTime(0),
      map(([countries, lnf]) => {
        const EMPTY_OPTION: AuctionSearchDropdownOption = {
          value: undefined,
          label: this.translateService.instant('auction-filter.any'),
        };

        return {
          countries,
          years: [
            EMPTY_OPTION,
            ...CarOnSaleUtils.EZS.map(year => ({
              value: year.toString(),
              label: year.toString(),
            })),
          ],
          price: [
            EMPTY_OPTION,
            ...CarOnSaleUtils.PRICES.map(price => ({
              value: price,
              label: decimalPipe.transform(price),
            })),
          ],
          mileage: [
            EMPTY_OPTION,
            ...CarOnSaleUtils.MILEAGES.map(i => ({
              value: i,
              label: decimalPipe.transform(i),
            })),
          ],
          transmission: enumToArray(ETransmissionType, ETransmissionType.UNKNOWN).map(transmission => ({
            value: transmission,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleTransmission(transmission)),
          })),
          horsePower: [
            EMPTY_OPTION,
            ...CarOnSaleUtils.generateNumberSequence(MIN_PS, MAX_PS, [[MAX_PS, STEP_PS]]).map(hp => ({
              value: hp,
              label: hp.toString(),
            })),
          ],
          kw: [
            EMPTY_OPTION,
            ...CarOnSaleUtils.generateNumberSequence(MIN_PS, MAX_PS, [[MAX_PS, STEP_PS]]).map(value => {
              return {
                value,
                label: this.convertHPtoKW(value).toString(),
              };
            }),
          ],
          fuelTypes: enumToArray(EFuelType, EFuelType.UNKNOWN).map(fuelType => ({
            value: fuelType,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleFuelType(fuelType)),
          })),
          vehicleColors: enumToArray(EVehicleColor, EVehicleColor.UNKNOWN).map(vehicleColor => ({
            value: vehicleColor,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleColor(vehicleColor)),
          })),
          euroNorm: enumToArray(EEuroNorm, EEuroNorm.OTHER).map(norm => ({
            value: norm,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleEuroNorm(norm)),
          })),
          preOwners: [
            EMPTY_OPTION,
            ...Array.from(Array(9), (_, i) => {
              const numberOfPreOwners = i + 1;
              return {
                value: numberOfPreOwners,
                label: this.translateService.instant('auction-filter.previous-owners', { numberOfPreOwners }),
              };
            }),
          ],
          vehicleCategory: enumToArray(EVehicleCategory, EVehicleCategory.UNKNOWN).map(category => ({
            value: category,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleCategory(category)),
          })),
          numberOfDoors: enumToArray(EVehicleDoors, EVehicleDoors.UNKNOWN).map(doors => ({
            value: doors,
            label: this.translateService.instant(I18nFormatUtils.formatVehicleDoors(doors)),
          })),
          numberOfSeats: [EMPTY_OPTION, ...Array.from(Array(7), (_, i) => i + 2).map(seats => ({ value: seats, label: seats.toString() }))],
          searchRadius: [
            EMPTY_OPTION,
            ...[50, 100, 150, 200, 300, 400, 500].map(distance => ({
              value: distance,
              label: this.translateService.instant('auction-filter.distance-km', { distance }),
            })),
          ],
          readyToDrive: [
            {
              value: EVehicleReadyToDriveStatus.YES,
              label: this.translateService.instant('vehicle.ready-to-drive.yes'),
            },
            {
              value: EVehicleReadyToDriveStatus.NO,
              label: this.translateService.instant('vehicle.ready-to-drive.no'),
            },
            {
              value: EVehicleReadyToDriveStatus.CONDITIONALLY,
              label: this.translateService.instant('vehicle.ready-to-drive.conditionally'),
            },
          ],
          taxation: [
            {
              value: undefined,
              label: this.translateService.instant('general.not-relevant'),
            },
            {
              value: true,
              label: this.translateService.instant('auction.vat-taxation-label-for-country.regular.??'),
            },
            {
              value: false,
              label: this.translateService.instant('auction.vat-taxation-label-for-country.differential.??'),
            },
          ],
          sellerType: [
            {
              value: ESellerBusinessType.TRADER,
              label: this.translateService.instant('auction.seller-type-label.trader'),
            },
            {
              value: ESellerBusinessType.LICENSED,
              label: this.translateService.instant('auction.seller-type-label.licensed'),
            },
          ],
          minAskPrice: [
            {
              value: undefined,
              label: this.translateService.instant('general.not-relevant'),
            },
            {
              value: true,
              label: this.translateService.instant('auction.seller-view.auction-card.min-ask-reached'),
            },
            {
              value: false,
              label: this.translateService.instant('auction.seller-view.auction-card.min-ask-not-reached'),
            },
          ],
          advertisementTypes: lnf?.visibleAdvertisementTypes.map(option => ({ value: option, label: option })) || [],
        };
      }),
    );
  }
}
