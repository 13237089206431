import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NotificationCenterService } from '@cosCoreFeatures/common/notification-center/services/notification.service';
import { ICosNotification, IPage } from '@caronsale/cos-models';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-notification-component',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  public isNotificationDialogVisible = false;
  public countUnreadNotifications = 0;
  public notificationPage$ = new BehaviorSubject<IPage<ICosNotification>>(null);

  private removeClickHandlerFunction: () => void;
  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    private notificationService: NotificationCenterService,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    // push new pages into our notification page stream to the child
    this.notificationService.notificationPageLoaded$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => this.notificationPage$.next(result));

    this.notificationService.countUnreadNotifications$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => (this.countUnreadNotifications = result.count));
    // initial request for the unread count
    this.notificationService.getUnreadNotificationsCount();

    this.notificationService.newNotification$.pipe(takeUntil(this.unsubscribe$)).subscribe((newNotification: ICosNotification) => {
      // new notifications do not increase the "unread" count since they are displayed immediately, either by the notification-dialog or by a toast.
      // both of them issue the "READ" engagement to the backend, so the (momentarily increased) count will decrease immediately again.
      if (this.isNotificationDialogVisible) {
        // add new notification to currentPage (notification-dialog will ignore duplicates)
        const currentPage = this.notificationPage$.value;
        if (currentPage) {
          currentPage.items.unshift(newNotification); // order actually doesn't matter, notification-dialog will sort all by date
          currentPage.total += 1;
          this.notificationPage$.next(currentPage);
        }
      } else if (newNotification.topic === 'auction.buyer.auctionGuaranteeAvailableToBook') {
        this.notificationService.showGuaranteeAdvertiseToast(newNotification);
      } else {
        // do not increase the unread count, since the toast does not
        this.notificationService.showToastIfNotOnNotificationTargetPage(newNotification);
      }
    });

    this.notificationService.engagedNotifications$.pipe(takeUntil(this.unsubscribe$)).subscribe(notifications => {
      // instead of trying to subtract the new "READ" engagements, ask the backend for the current unread number again
      if (notifications?.length) {
        this.notificationService.getUnreadNotificationsCount();
      }
    });

    this.notificationService.closeNotificationDialog$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setNotificationDialogVisible(false);
    });
  }

  public ngOnDestroy() {
    this.notificationService.disconnect();
    this.removeWindowClickHandler();
    this.unsubscribe$.next();
  }

  public toggleNotificationDialog() {
    this.setNotificationDialogVisible(!this.isNotificationDialogVisible);
  }

  private setNotificationDialogVisible(isVisible: boolean): void {
    this.isNotificationDialogVisible = isVisible;

    if (isVisible) {
      // close the notification dialog if something was clicked outside of us (that bubbled up to 'window')
      this.removeClickHandlerFunction = this.renderer2.listen('window', 'click', event => {
        if (!this.elementRef.nativeElement.contains(event.target)) {
          this.setNotificationDialogVisible(false);
        }
      });
    } else {
      this.removeWindowClickHandler();
    }
  }

  private removeWindowClickHandler(): void {
    if (this.removeClickHandlerFunction) {
      this.removeClickHandlerFunction();
      this.removeClickHandlerFunction = undefined;
    }
  }
}
